.op-logo
  width: 230px
  height: var(--header-height)

  &--link
    margin-top: 3px
    width: 100%
    display: block
    height: 30px
    background: var(--header-home-link-bg)
    text-indent: -9999em
    background-position: center
    background-size: contain
    background-repeat: no-repeat

  @include breakpoint(850px down)
    display: none

